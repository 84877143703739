import React from 'react';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { motion } from 'framer-motion';

const CookieBar = ({ setShowCookieBar }) => {
  const location = useLocation();

  const handleAcceptCookies = (event) => {
    event.preventDefault();
    initializeAndTrack(location);
    document.cookie = 'gatsby-gdpr-google-analytics=true';
    document.cookie = 'agency-x-cookie-policy-set=true';
    setShowCookieBar(false);
  };
  const handleDeclineCookies = (event) => {
    event.preventDefault();
    document.cookie = 'gatsby-gdpr-google-analytics=false';
    document.cookie = 'agency-x-cookie-policy-set=true';
    setShowCookieBar(false);
  };
  return (
    <Overlay
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ ease: 'easeInOut', duration: 0.375, delay: 0.375 }}>
      <Container>
        <h3 data-nosnippet="">We love cookies at Agency X!</h3>
        <p data-nosnippet="">
          When you Allow cookies, that means you agree to the storing of cookies on your device for
          the purpose of enhancing site navigation, analyzing site usage, and assisting in our
          marketing efforts.
        </p>
        <ButtonContainer>
          <Button
            data-nosnippet=""
            style={{ marginTop: '1.05rem' }}
            onClick={handleDeclineCookies}
            whileHover={{
              scale: 1.0375,
              transition: { ease: 'easeInOut', duration: 0.375 }
            }}
            whileTap={{ scale: 0.9 }}>
            Decline
          </Button>
          <Button
            data-nosnippet=""
            style={{ marginTop: '1.05rem' }}
            onClick={handleAcceptCookies}
            invert
            whileHover={{
              scale: 1.0375,
              transition: { ease: 'easeInOut', duration: 0.375 }
            }}
            whileTap={{ scale: 0.9 }}>
            Allow
          </Button>
        </ButtonContainer>
      </Container>
    </Overlay>
  );
};

const Overlay = styled(motion.div)`
  align-items: flex-end;
  background: transparent;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 4;
  pointer-events: none;
  position: fixed;
`;

const Container = styled(motion.div)`
  align-items: center;
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 1rem;
  margin: 1rem;
  margin-bottom: 50%;
  max-width: 100vw;
  z-index: 10;
  h3 {
    color: black;
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 38px;
    margin-bottom: 0.5rem;
    text-align: center;
  }
  p {
    color: black;
    font-size: 1rem;
    line-height: 24px;
    text-align: center;
    max-width: 500px;
  }
  @media only screen and (min-width: 760px) {
    padding: 30px 100px;
    margin: 5rem;
    h3 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1rem;
    }
  }
  @media only screen and (min-width: 1200px) {
    margin: 4rem;
  }
`;

const Button = styled(motion.button)`
  align-items: center;
  border-radius: 0.125em;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 700;
  height: 3em;
  justify-content: center;
  transition: background-color 150ms ease-in-out, color 150ms ease-in-out;
  pointer-events: auto;
  text-decoration: none;
  text-transform: uppercase;
  width: 10.375em;

  &:hover {
    background-color: ${({ invert }) => (invert ? 'var(--ax-gold)' : 'var(--ax-gold)')};
    color: #fff;

    :active {
      border: 0.063em solid #000;
      background-color: #000;
      color: #fff;
      transition: background-color 75ms ease-in-out, border 75ms ease-in-out, color 75ms ease-in-out;
    }

    :not(:active) {
      transition: background-color 75ms ease-in-out, border 75ms ease-in-out, color 75ms ease-in-out;
    }
  }

  &:not(:hover) {
    :visited {
      color: ${({ invert }) => (invert ? '#fff' : 'var(--ax-gold)')};
    }

    :not(:active) {
      border: 0.063em solid ${({ invert }) => (invert ? 'none' : 'var(--ax-gold)')};
      background-color: ${({ invert }) => (invert ? 'var(--ax-gold)' : 'transparent')};
      color: ${({ invert }) => (invert ? '#fff' : 'var(--ax-gold)')};
      transition: background-color 150ms ease-in-out, border 150ms ease-in-out;
    }
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export default CookieBar;
