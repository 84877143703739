import React, { useState, useEffect } from 'react';
import { Script } from 'gatsby';
import { Header, Footer, Transition, ContactUsButton, CookieBar } from 'components';
import { AnimatePresence, motion } from 'framer-motion';
import { GlobalStyle } from 'shared_styles';
import { isMobileOnly } from 'react-device-detect';
import { useWindowSize, useIsomorphicLayoutEffect } from 'hooks';
import styled, { keyframes, css } from 'styled-components';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollSmoother from 'gsap/ScrollSmoother';
import { gsap } from 'gsap';
import { SmootherContext } from 'context';
import '../../fonts/fonts.css';

/* const CookieHubScript = () => {
  const cpm = {};
  (function (h, u, b) {
    var d = h.getElementsByTagName('script')[0],
      e = h.createElement('script');
    e.async = true;
    e.src = 'https://cookiehub.net/c2/724e30f3.js';
    e.onload = function () {
      u.cookiehub.load(b);
    };
    d.parentNode.insertBefore(e, d);
  })(document, window, cpm);
}; */

function Layout({ children, location }) {
  const [smoother, setSmoother] = useState();

  useIsomorphicLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

    const _smoother = ScrollSmoother.create({
      smooth: 1,
      normalizeScroll: true, // prevents address bar from showing/hiding on most devices, solves various other browser inconsistencies
      ignoreMobileResize: true, // skips ScrollTrigger.refresh() on mobile resizes from address bar showing/hiding
      effects: true
    });

    setSmoother(_smoother);
  }, []);

  useEffect(() => {
    if (document.cookie.indexOf('agency-x-cookie-policy-set=') === -1) {
      setShowCookieBar(true);
    }
  }, []);

  const { pathname } = location;
  const [showCookieBar, setShowCookieBar] = useState(false);
  const { windowWidth } = useWindowSize();

  return (
    <>
      <SmootherContext.Provider value={smoother}>
        <GlobalStyle />
        <Header />
        <div id="smooth-content">
          <Wrapper
            id="top"
            pathname={pathname}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0 }}>
            <AnimatePresence exitBeforeEnter>
              {showCookieBar && <CookieBar setShowCookieBar={setShowCookieBar} />}
            </AnimatePresence>
            <Transition pathname={pathname}>
              <main>{children}</main>
            </Transition>
            <AnimatePresence>
              {!isMobileOnly && pathname !== '/contact/' && pathname !== '/logobook/' && (
                <ContactUsButton />
              )}
            </AnimatePresence>
            {pathname !== '/contact/' || (pathname === '/contact/' && windowWidth < 1200) ? (
              <Footer setShowCookieBar={setShowCookieBar} />
            ) : null}
          </Wrapper>
        </div>
      </SmootherContext.Provider>
      {/* <Script
        src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charset="UTF-8"
        data-domain-script="29633e4b-cad4-4977-8c84-3771d83d1c95-test"
      /> */}
      {/* <Script type="text/javascript">function OptanonWrapper() {}</Script> */}
    </>
  );
}

const fadeIn = keyframes`
  to {
    opacity: 1;
  }
`;

const Wrapper = styled(motion.div)`
  background-color: ${({ pathname }) =>
    pathname === '/logobook/' ? 'var(--ax-black)' : 'var(--ax-beige)'};
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative !important;

  main > section {
    ${({ pathname }) =>
      pathname === '/' &&
      css`
        opacity: 0;
        animation: ${fadeIn} 750ms ease-in-out 0ms forwards;
        @media (min-width: 450px) {
          animation: ${fadeIn} 500ms ease-in-out 1250ms forwards;
        }
      `}

    display: grid;
    grid-template-columns:
      1.25rem
      1fr
      1.25rem;

    @media screen and (min-width: 48rem) {
      grid-template-columns:
        6.25%
        1fr
        6.25%;
    }

    @media screen and (min-width: 90rem) {
      grid-template-columns:
        9%
        1fr
        9%;
    }
  }

  main {
    position: relative;
    grid-area: 1 / 1 / -1 / -1;
  }
`;

export default Layout;
