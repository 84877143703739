import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --ax-white: #fff;
    --ax-beige: #efede4;
    --ax-gold: #ce9200;
    --ax-grey: #c1beac;
    --ax-black: #1d1d1b;
    --ax-purple: #5d3995;
    --ax-pink: #ee2856;
    --ax-green: #1a9754;
    --ax-blue: #179dd8;
  }

  html {
    box-sizing: border-box;


  }

  html,
  body {
    width: 100%;
    height: 100%;
  }

  *, *:before, *:after {
    box-sizing: inherit;

  }
  body {
    color: #000;
    background: var(--ax-black);
    overflow-x: hidden;
  }
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  li {
    margin: 0;
    padding: 0;
    font-family: "proxima-nova";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display:swap;

  }

  button {
    border: none;
  }
`;
