import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { SectionTitle, Link } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useWindowSize } from 'hooks';
import { useStaticQuery, graphql } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';

const Clients = () => {
  const { windowWidth } = useWindowSize();
  const data = useStaticQuery(graphql`
    query LogoQuery {
      logos: allFile(
        filter: { relativeDirectory: { eq: "home/logos" } }
        sort: { fields: name, order: [ASC] }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 120
                placeholder: BLURRED
                formats: AUTO
                quality: 100
              )
            }
          }
        }
      }
    }
  `);
  const { logos } = data;

  const [slide, setSlide] = useState(0);
  const paginate = () => {
    if (slide === 0) {
      setSlide(8);
    } else if (slide === 8) {
      setSlide(16);
     } else {
      setSlide(0);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => paginate(), 5000);
    return () => clearTimeout(timer);
  }, [slide]);

  return (
    <Wrapper>
      <div>
        <SectionTitle capitalize>
          Trust us.
          <br />
          Our clients do.
        </SectionTitle>
        <p>
          With our expertise in pharmaceutical and healthcare marketing, many of our clients have
          been with us for a decade or more. They appreciate the results we produce and trust us
          with their brand messaging.
        </p>
        {windowWidth >= 768 && (
          <Link to="/contact/" id="get-in-touch">
            Get In Touch
          </Link>
        )}

        <AnimatePresence exitBeforeEnter>
          <Logos>
            {logos.edges
              .map((logo, i) => (
                <LogoContainer
                  key={i + slide}
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0 }}
                  transition={{ duration: 0.5 }}>
                  <Logo
                    image={getImage(logo.node)}
                    alt={logo.node.name}
                    imgStyle={{
                      objectFit: 'contain'
                    }}
                  />
                </LogoContainer>
              ))
              .slice(slide, slide + 8)}
          </Logos>
        </AnimatePresence>

        {windowWidth < 768 && (
          <Link
            to="/contact/"
            id="get-in-touch"
            style={{
              margin: '2.625rem 0 3.688rem 50%',
              transform: 'translate3d(-50%, 0, 0)'
            }}>
            Get In Touch
          </Link>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background-color: var(--ax-beige);
  @media screen and (min-width: 48rem) {
    padding: 3.9rem 0 5.7rem;
  }
  @media screen and (min-width: 68.75rem) {
    padding: 8.45rem 0 5.7rem;
  }
  > div {
    grid-column: 2 / 3;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    > p {
      font-size: 0.875rem;
      line-height: 1.429em;
      margin: 2em 0 3.55em;
    }
    @media screen and (min-width: 48rem) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto minmax(124px, 1fr);
      grid-column-gap: 20px;
      > h3 {
        margin: 0 0 0.875em;
      }
      > p {
        margin: 0 0 2.95em;
        grid-area: 2 / 1;
      }
      > a:first-of-type {
        grid-area: 3 / 1;
      }
    }
    @media screen and (min-width: 78.75rem) {
      grid-template-columns: 1fr 2fr;
      grid-column-gap: 140px;
      > p {
        max-width: 23.071em;
      }
    }
  }
`;

const Logos = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row: 2;
  grid-gap: 2.125rem 1rem;
  justify-items: center;
  align-items: start;
  margin-bottom: 2rem;
  @media screen and (min-width: 48rem) {
    grid-gap: 2rem 2rem;
    justify-items: space-between;
    grid-row: 2;
    padding: 0 2rem;
  }
  @media screen and (min-width: 78.75rem) {
    grid-gap: 2.5em 5em;
    grid-template-columns: repeat(4, 100px);
  }
  @media screen and (min-width: 92rem) {
    grid-gap: 2.5em 5em;
    grid-template-columns: repeat(4, 120px);
  }
`;

const LogoContainer = styled(motion.div)`
  width: 100%;
  max-height: 2rem;
`;

const Logo = styled(GatsbyImage)`
  /* cursor: pointer; */
  max-width: 7.5rem;
  transition: filter 200ms ease-in-out, transform 200ms ease-in-out;
  width: 100%;
  height: 100%;
  &:hover {
    filter: none;
    transform: scale3d(1.125, 1.125, 1.125);
  }
  &:not(:hover) {
    filter: grayscale(100%) brightness(85%) contrast(350%);
    transform: scale3d(1, 1, 1);
  }
`;

export default Clients;
